import filter from 'lodash/filter'
import takeRight from 'lodash/takeRight'
import uniq from 'lodash/uniq'

export type PropertyLocationLabelInput = {
  /** i.e.: Berlin, Nordrhein-Westfalen */
  region?: string | null

  /** i.e.: Berlin, Köln */
  city?: string | null

  /** i.e.: Charlottenburg, Kalk */
  location_name?: string | null
}

export const getPropertyLocationLabel = ({
  region,
  city,
  location_name,
}: PropertyLocationLabelInput) => {
  // These are ordered by specificity. (Bigger areas first)
  let parts = [region, city, location_name]

  // Filter out non-defined parts
  parts = filter(parts, (part) => !!part)

  // Avoid things like "Berlin / Berlin"
  parts = uniq(parts)

  // Only take the 2 most specific ones.
  parts = takeRight(parts, 2)

  const locationLabel = parts.join(' / ')
  return locationLabel
}
