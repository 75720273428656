import { useLocale } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import type { Favorite } from '@dreamstack/web/lib/prisma/favorites'
import { useQueryClient } from '@tanstack/react-query'
import { useSessionTyped } from '../../../auth/src/lib/useSession'

export const useAddFavoriteOptimistic = () => {
  const locale = useLocale()
  const queryClient = useQueryClient()
  const session = useSessionTyped()

  const queryIdentifier = [
    ['favorites', 'getMyFavorites'],
    { input: { locale }, type: 'query' },
  ]

  const { mutateAsync: addFavorite } =
    trpc.favorites.upsertFavorite.useMutation({
      onMutate: async ({ propertyId, note }) => {
        await queryClient.cancelQueries(queryIdentifier)
        const previousFavorites: Favorite[] | undefined =
          queryClient.getQueryData(queryIdentifier)

        const newFavorites: Favorite[] = [
          ...(previousFavorites ?? []),
          {
            propertyId,
            userId: session?.id ?? '',
            note: note ?? null,
            createdAt: new Date(),
            property: {
              id: propertyId,
            } as any,
          },
        ]
        queryClient.setQueryData(queryIdentifier, newFavorites)
        return { previousFavorites }
      },
      onError: (_err, _deletedFavorite, context) => {
        queryClient.setQueryData(queryIdentifier, context?.previousFavorites)
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryIdentifier)
      },
    })
  return addFavorite
}
