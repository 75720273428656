import dropRight from 'lodash/dropRight'
import join from 'lodash/join'
import last from 'lodash/last'
import split from 'lodash/split'

export type PropstackImageSizes =
  | 'default'
  | 'square'
  | 'small_thumb'
  | 'thumb'
  | 'medium'
  | 'big'

type TransformPropstackImageUrlParams = {
  url?: string | null
  size?: PropstackImageSizes
  decode?: boolean
}

export const transformPropstackImageUrl = ({
  url,
  size = 'medium',
  decode = true,
}: TransformPropstackImageUrlParams) => {
  if (!url) return url ?? null
  const urlParts = split(url, '/')
  const back = last(urlParts)
  const frontParts = dropRight(urlParts)
  const sizePrefix = size === 'default' ? '' : `${size}_`
  const newBack = `${sizePrefix}${back}`
  const newUrl = join([...frontParts, newBack], '/')

  // decode url for next image to understand
  return decode ? decodeURI(newUrl) : newUrl
}
