import { useLocale } from '@dreamstack/i18n'
import { useCallback } from 'react'
import { useIsReservationUi } from '../../next/useIsReservationUi'
import type {
  GetPropertyUrlPropsWithoutLocale} from './getPropertyUrl';
import {
  getPropertyUrl
} from './getPropertyUrl'

export const useGetPropertyUrl = () => {
  const isReservationUi = useIsReservationUi()
  const locale = useLocale()
  return useCallback(
    (options: GetPropertyUrlPropsWithoutLocale) => {
      if (isReservationUi) {
        return `/reservations/${options.id}`
      }
      const url = getPropertyUrl({ ...options, locale })
      return url
    },
    [locale, isReservationUi]
  )
}
