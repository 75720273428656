import {
  getSession,
  useHandleAuthFlowForAction,
  useUserId,
} from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { Heart, HeartLiked } from '@dreamstack/icons'
import { trpc } from '@dreamstack/trpc/client'
import { useDeleteFavoriteOptimistic } from '@dreamstack/web-graphql'
import { useAddFavoriteOptimistic } from '@dreamstack/web-graphql/src/hooks/useAddFavoriteOptimistic'
import type { ReducedPropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import some from 'lodash/some'
import type { FunctionComponent, MouseEvent, SVGProps } from 'react'
import { useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { useLocale } from '../../../i18n/src/locale/useLocale'
import { useNotificationPopover } from '../hooks/useNotificationPopover'
import { useMyAccentroBaseUrl } from '../index'

const StyledIconWrapper = styled.div<SVGProps<SVGSVGElement>>(({}) => [
  tw`cursor-pointer min-width[32px] `,
])

export const MyLikeButton: FunctionComponent<
  React.PropsWithChildren<{
    property: ReducedPropertyPreview
    propertyId: number
    toggletemporaryFavorites?: (propertyId: number) => void
  }>
> = ({ property, propertyId, toggletemporaryFavorites }) => {
  const locale = useLocale()
  const userId = useUserId()
  const { data, refetch } = trpc.favorites.getMyFavorites.useQuery(
    { locale },
    { enabled: !!userId, trpc: { ssr: false }, staleTime: 0 }
  )

  const { mutateAsync: sendEventFavoriteProp } =
    trpc.tracking.trackEvent.useMutation()

  const liked = useMemo(() => {
    return some(data, (f) => f.propertyId === propertyId)
  }, [data, propertyId])

  const handleAuth = useHandleAuthFlowForAction()
  const addFavoriteOptimistic = useAddFavoriteOptimistic()
  const deleteFavorite = useDeleteFavoriteOptimistic()

  const t = useTranslation()

  const myAccentroLink = useMyAccentroBaseUrl()
  const {
    NotificationPopoverComponent,
    openNotification,
    setReferenceElement,
  } = useNotificationPopover({
    title: t('accentro:my.addedFavorite'),
    buttonHref: `/${myAccentroLink}`,
    buttonText: t('accentro:my.myFavoriteList'),
    type: 'success',
    automaticCloseMs: 10000,
    options: { placement: 'left-start' },
    isUnique: true,
    identifierType: 'AddFavorite',
    id: propertyId.toString(),
    offset: [0, 20],
  })

  const onLikeProperty = (event: MouseEvent<Element>) => {
    event.preventDefault()
    if (toggletemporaryFavorites) toggletemporaryFavorites(propertyId)
    const actionCallback = async () => {
      const safeUserId = userId || (await getSession())?.id
      if (!safeUserId) {
        return
      }
      if (liked) {
        await deleteFavorite({ propertyId })
      } else {
        await addFavoriteOptimistic({ propertyId })

        // Open notification popover on like
        openNotification()

        // Cio tracking
        await sendEventFavoriteProp({
          eventName: 'favoriteProperty',
          data: {
            property_id: propertyId,
          },
        })
      }
    }
    handleAuth({
      actionCallback,
    })
  }
  if (!propertyId) return null
  return (
    <div>
      <StyledIconWrapper
        ref={setReferenceElement}
        onClick={(event) => onLikeProperty(event)}
      >
        {liked ? <HeartLiked /> : <Heart />}
      </StyledIconWrapper>
      {NotificationPopoverComponent}
    </div>
  )
}
