import { useLocale } from '@dreamstack/i18n'
import { trpc } from '@dreamstack/trpc/client'
import type { Favorite } from '@dreamstack/web/lib/prisma/favorites'
import { useQueryClient } from '@tanstack/react-query'

export const useDeleteFavoriteOptimistic = () => {
  const locale = useLocale()
  const queryClient = useQueryClient()

  const queryKey = [
    ['favorites', 'getMyFavorites'],
    { input: { locale }, type: 'query' },
  ]

  const { mutateAsync: deleteFavoriteTrpc } =
    trpc.favorites.deleteFavorite.useMutation({
      onMutate: async ({ propertyId }) => {
        await queryClient.cancelQueries(queryKey)
        const previousFavorites = queryClient.getQueryData(queryKey)
        const arr = previousFavorites as Favorite[] | undefined
        const newData =
          arr?.filter((favorite) => favorite.propertyId !== propertyId) ?? []
        queryClient.setQueryData(queryKey, newData)
        return { previousFavorites }
      },
      onError: (_err, _deletedFavorite, context) => {
        queryClient.setQueryData(queryKey, context?.previousFavorites)
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    })
  return deleteFavoriteTrpc
}
